import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const errorMessageMappings = {
  missing: 'signup.profile.error.blank',
  confirmation: 'signup.profile.error.password.confirmation'
}

const Password = ({ password, passwordConfirmation, onChange, t, errors }) => {
  let passwordError
  let passwordConfirmationError
  if (errors.user && errors.user.password) {
    passwordError = <Error messageKey={errorMessageMappings[errors.user.password]} />
  }
  if (errors.user && errors.user.password_confirmation) {
    passwordConfirmationError = <Error messageKey={errorMessageMappings[errors.user.password_confirmation]} />
  }

  const handleInvalid = (event) => {
    if (event.target.name === 'passwordConfirmation') {
      setError('confirmation')
      event.target.setCustomValidity(t('signup.profile.error.password.confirmation'))
    }
  }

  const unsetError = () => {
    if (errors.user && errors.user.password_confirmation === 'confirmation') {
      errors.user.password_confirmation = null
    }
  }

  const setError = (key) => {
    if (!errors.users) {
      errors.user = { password_confirmation: key }
    }
  }

  const verifyPassword = (event) => {
    if (event.target.name === 'passwordConfirmation') {
      event.target.setCustomValidity('')
      if (event.target.value !== password) {
        setError('confirmation')
        event.target.setCustomValidity(t('signup.profile.error.password.confirmation'))
      } else {
        unsetError()
      }
    }
  }

  return (
    <>
      <div>
        <input
          className={passwordError ? 'error' : ''}
          name='password'
          type='password'
          value={password}
          placeholder={t('signup.profile.password')}
          onChange={onChange}
          autoComplete='new-password'
          id='password'
        />
        {passwordError}
      </div>
      <div>
        <input
          className={passwordError ? 'error' : ''}
          name='passwordConfirmation'
          type='password'
          value={passwordConfirmation}
          placeholder={t('signup.profile.password_confirmation')}
          onChange={(e) => { verifyPassword(e); onChange(e) }}
          pattern={password}
          onInvalid={handleInvalid}
          autoComplete='password'
          id='password-confirmation'
        />
        {passwordConfirmationError}
      </div>
    </>
  )
}

export default translate()(Password)
