import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'
import countries from './country'

class Mobile extends React.Component {
  constructor (props) {
    super(props)

    this.all_prefixes = countries.map((e) => e.prefix)
    this.restricted_prefixes = countries.filter((e) => e.whitelisted).map((e) => e.prefix)

    this.errorMessageMappings = {
      missing: 'signup.profile.error.blank',
      invalid: 'signup.profile.error.mobile_number.invalid',
      invalid_format: 'signup.profile.error.mobile_number.invalid',
      mobile_number_already_in_use: 'signup.profile.error.mobile_number.already_in_use'
    }
  }

  errorMessage = () => {
    const { errors } = this.props

    if (errors.user && errors.user.mobile && errors.user.mobile.number) {
      const messageKey = this.errorMessageMappings[errors.user.mobile.number]
      return <Error messageKey={messageKey} />
    }
    return null
  }

  setMatchPattern = () => {
    if (this.props.mobilePrefix && countries.find(c => c.prefix === this.props.mobilePrefix).validator) {
      return countries.find(c => c.prefix === this.props.mobilePrefix).validator
    } else {
      return '^[0-9]{7,12}$'
    }
  }

  unsetError = () => {
    const { errors } = this.props
    if (errors.user && errors.user.mobile && errors.user.mobile.number === 'invalid_format') {
      errors.user.mobile = null
    }
  }

  setError = (key) => {
    const { errors } = this.props
    if (!errors.users) {
      errors.user = {}
    }
    if (!errors.user.mobile || !errors.user.mobile.number) {
      errors.user.mobile = { number: key }
    }
  }

  validate = (event) => {
    // need to reset the custom validity error message or it will persist even when data is corrected
    event.target.setCustomValidity('')
    if (event.target.value === null || event.target.value === '') {
      this.setError('missing')
    } else if (!event.target.value.match(event.target.pattern)) {
      this.setError('invalid_format')
    } else {
      this.unsetError()
    }
  }

  // set custom error message when field fails validation
  handleInvalid = (event, t) => {
    const digits = event.target.pattern.split(/[{}]/)?.[1].replace(',', ' to ')
    event.target.setCustomValidity(t('signup.profile.error.mobile_number.validation', { digits, interpolation: { prefix: '%{', suffix: '}' } }))
  }

  render () {
    // if signup requires verification then only allow whitelisted prefixes
    const prefixes = this.props.restricted ? this.restricted_prefixes : this.all_prefixes
    const prefixOptions = prefixes.map((prefix) =>
      <option key={prefix} value={prefix}>{prefix}</option>
    )
    const { t } = this.props

    return (
      <div className='mobile'>
        <select
          className='mobilePrefixSelect'
          name='mobilePrefix'
          value={this.props.mobilePrefix}
          onChange={this.props.onChange}
          id='mobile-prefix'
          autoComplete='tel-country-code'
        >
          {prefixOptions}
        </select>
        <input
          type='text'
          className={this.errorMessage() ? 'error' : ''}
          name='mobileNumber'
          value={this.props.mobileNumber}
          pattern={this.setMatchPattern()}
          onInvalid={(e) => this.handleInvalid(e, t)}
          onChange={(e) => { this.validate(e); this.props.onChange(e) }}
          placeholder={t('signup.profile.mobile_number')}
          id='mobile-number'
          autoComplete='tel-national'
        />
        {this.errorMessage()}
      </div>
    )
  }
}

export default translate()(Mobile)
