import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const errorMessageMappings = {
  missing: 'signup.profile.error.blank',
  invalid: 'signup.profile.error.email.invalid',
  invalid_format: 'signup.profile.error.email.invalid',
  email_already_in_use: 'signup.profile.error.email.already_in_use',
  confirmation: 'signup.profile.error.email.confirmation'
}

const Email = ({ email, emailConfirmation, onChange, t, errors }) => {
  let emailError
  let emailConfirmationError
  if (errors.user && errors.user.email) {
    emailError = <Error messageKey={errorMessageMappings[errors.user.email]} />
  }
  if (errors.user && errors.user.email_confirmation) {
    emailConfirmationError = <Error messageKey={errorMessageMappings[errors.user.email_confirmation]} />
  }

  // force emails to be lowercase when submitted
  const onFocusOut = (e) => {
    e.target.value = e.target.value.toLowerCase()
    // ensure change is added to state
    onChange(e)
  }

  const handleInvalid = (event) => {
    if (event.target.name === 'emailConfirmation') {
      event.target.setCustomValidity(t('signup.profile.error.email.confirmation'))
    }
  }

  const unsetError = () => {
    if (errors.user && errors.user.email_confirmation === 'confirmation') {
      errors.user.email_confirmation = null
    }
  }

  const setError = (key) => {
    if (!errors.users) {
      errors.user = { email_confirmation: key }
    }
  }

  const verifyEmail = (event) => {
    if (event.target.name === 'emailConfirmation') {
      event.target.setCustomValidity('')
      if (event.target.value !== email) {
        setError('confirmation')
        event.target.setCustomValidity(t('signup.profile.error.email.confirmation'))
      } else {
        unsetError()
      }
    }
  }

  return (
    <div>
      <div>
        <input
          name='email' className={emailError ? 'error' : ''}
          type='email'
          placeholder={t('signup.profile.email')}
          value={email || ''}
          onChange={onChange}
          onBlur={onFocusOut}
          id='email'
          autoComplete='email'
        />
        {emailError}
      </div>
      <div>
        <input
          className={emailError ? 'error' : ''}
          name='emailConfirmation'
          type='email'
          placeholder={t('signup.profile.email_confirmation')}
          value={emailConfirmation}
          onChange={(e) => { verifyEmail(e); onChange(e) }}
          onBlur={onFocusOut}
          onInvalid={handleInvalid}
          id='email-confirmation'
          autoComplete='email'
        />
        {emailConfirmationError}
      </div>
    </div>
  )
}

export default translate()(Email)
